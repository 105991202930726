import { Box, Button, CircularProgress, IconButton, Typography } from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useReactMediaRecorder } from 'react-media-recorder';
import { EventNames } from '../../../common/constants/constants';
import { RecordPreview } from '../RecordPreview/RecordPreview';
import { VideoRecordButton } from '../VideoRecordButton/VideoRecordButton';
import { useStyles } from './RecordVideoComponent.helper';
import { RecordBackIcon } from '../../../common/assets/RecordBackIcon';
import { VideoToolStrings } from '../../../common/localization/en';
import { Counter } from './Counter/Counter';
import { RecordPlayIcon } from '../../../common/assets/RecordPlayIcon';
import { useTrackEvent } from '../../../common/hooks/useTrackEvent';
import SwitchCameraIcon from '../../../common/assets/playerIcons/SwitchCameraIcon';
import { useAppDispatch } from '../../../store/store';
import { setHideCrispChat } from '../../../store/slices/ui';

export const RECORD_TIME_LIMIT = 30;

interface RecordVideoComponentProps {
  setBlob: (val: string | undefined) => void;
  setFile: (val: File | null) => void;
  handleRemount: () => void;
  setStoryId: (val: string) => void;
  onFileChange: (file: File | null) => void;
  onRefresh: () => void;
  setIsRecordError: (error: boolean) => void;
  toggleCamera: () => void;
  camera: 'user' | 'environment';
}
const MEDIA_CREATING_TIMEOUT = 5000;

export const RecordVideoComponent: FC<RecordVideoComponentProps> = ({
  setBlob,
  handleRemount,
  setFile,
  onFileChange,
  onRefresh,
  setIsRecordError,
  toggleCamera,
  camera,
}) => {
  const classes = useStyles();
  const { status, startRecording, stopRecording, mediaBlobUrl, previewStream } = useReactMediaRecorder({
    video: {
      facingMode: camera,
    },
    askPermissionOnMount: true,
  });

  const { trackEvent } = useTrackEvent();

  const inputRef = useRef<HTMLInputElement | null>(null);
  const previewVideoRef = useRef<HTMLVideoElement | null>(null);

  const [isCountDownStated, setIsCountDownStated] = useState(false);
  const [isPreviewVideoPlaying, setIsPreviewVideoPlaying] = useState(false);
  const [isRecorded, setIsRecorded] = useState(false);
  const [isMediaCreatingTimeout, setIsMediaCreatingTimeout] = useState(false);
  const dispatch = useAppDispatch();

  const [isRearCameraSupported, setIsRearCameraSupported] = useState(false);

  useEffect(() => {
    dispatch(setHideCrispChat(true));
    return () => {
      dispatch(setHideCrispChat(false));
    };
  }, []);

  const checkRearCamera = () => {
    if (/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      setIsRearCameraSupported(true);
    }
  };

  const clearInput = useCallback(() => {
    if (inputRef?.current) {
      inputRef.current.value = '';
    }
  }, []);

  useEffect(() => {
    if (isRecorded) {
      setTimeout(() => {
        setIsMediaCreatingTimeout(true);
      }, MEDIA_CREATING_TIMEOUT);
    }
  }, [isRecorded]);

  const handleRecordingError = () => {
    onRefresh();
    setIsRecordError(true);
    trackEvent(EventNames.RecordVideoError);
  };

  useEffect(() => {
    checkRearCamera();
  }, []);

  useEffect(() => {
    if (isMediaCreatingTimeout && status === 'stopping') {
      handleRecordingError();
    }
  }, [isMediaCreatingTimeout, status]);

  useEffect(() => {
    if (isRecorded && mediaBlobUrl && status === 'stopped') {
      setTimeout(async () => {
        const fileBlob = await fetch(mediaBlobUrl).then((res) => res.blob());
        if (fileBlob.size === 0) {
          handleRecordingError();
        }
      }, 0);
    }
  }, [isRecorded, mediaBlobUrl, status]);

  useEffect(() => {
    // to show thumbnail preview on IOS

    previewVideoRef?.current?.load();
  }, [mediaBlobUrl]);

  useEffect(() => {
    trackEvent(EventNames.Record_Video_Page);
  }, [trackEvent]);

  const onChangeUploadVideoHandler = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0] || null;

      if (file) {
        trackEvent(EventNames.Record_Video_Page_File_Selected, {
          size: file.size,
        });
        clearInput();
        setFile(file);
        onFileChange(file);
        return;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clearInput, setFile],
  );

  const onBackClick = () => {
    trackEvent(EventNames.Back_Button_Click);
    handleRemount();
  };

  const onStartRecording = () => {
    trackEvent(EventNames.Record_Button_Click);

    setIsCountDownStated(true);
  };

  const handlePlay = () => {
    const video = previewVideoRef.current;

    if (video?.paused) {
      video.play();
    } else {
      video?.pause();
    }
  };

  const isRecording = status === 'recording';

  const recordingControls = useMemo(() => {
    return (
      <Box className={classes.previewControlsWrapper}>
        {isCountDownStated || isRecording ? (
          <Typography className={classes.recordText}>
            {isRecording ? VideoToolStrings.Recording : VideoToolStrings.GetReady}
          </Typography>
        ) : (
          <Typography className={classes.getReadyText}>
            PRESS{' '}
            <Typography display="inline" color="#F00">
              REC
            </Typography>{' '}
            WHEN READY
          </Typography>
        )}
        <Box className={classes.buttonsWrapper}>
          <Box display="flex" className={classes.backButton}>
            <IconButton color="inherit" onClick={onBackClick} aria-label="back" size="large" sx={{ fontSize: '36px' }}>
              <RecordBackIcon />
            </IconButton>
          </Box>
          <Box>
            <VideoRecordButton
              handleStart={onStartRecording}
              handleStop={() => {
                stopRecording();
                setIsRecorded(true);
              }}
              isRecording={isRecording}
            />
          </Box>
          {isRearCameraSupported && !isRecording && (
            <Box
              onClick={() => {
                toggleCamera();
                onRefresh();
              }}
              style={{ display: 'flex', justifyContent: 'right', marginRight: '39px' }}
            >
              <SwitchCameraIcon />
            </Box>
          )}
        </Box>
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startRecording, stopRecording, status, mediaBlobUrl, handleRemount, onChangeUploadVideoHandler]);

  return (
    <Box className={classes.previewWrapper}>
      {!mediaBlobUrl && (
        <>
          <Counter
            initialValue={3}
            setIsCountStarted={setIsCountDownStated}
            startRecording={startRecording}
            isCountDownStarted={isCountDownStated}
            isRecording={isRecording}
          />
          <RecordPreview stream={previewStream} />
          {recordingControls}
        </>
      )}
      {mediaBlobUrl && (
        <Box className={classes.videoPreviewWrapper}>
          <video
            src={mediaBlobUrl}
            className={classes.videoPreview}
            ref={previewVideoRef}
            onClick={handlePlay}
            onPlay={() => {
              setIsPreviewVideoPlaying(true);
            }}
            onPause={() => {
              setIsPreviewVideoPlaying(false);
            }}
            onEnded={() => {
              setIsPreviewVideoPlaying(false);
            }}
            autoPlay={false}
            controls={false}
            playsInline={true}
            loop={true}
            preload="auto"
          ></video>
          {!isPreviewVideoPlaying && (
            <IconButton className={classes.playIcon} onClick={handlePlay}>
              <RecordPlayIcon />
            </IconButton>
          )}
          <Box className={classes.previewControlsWrapper}>
            <Box className={classes.submitControlsWrapper}>
              <Button
                variant="text"
                className={classes.retakeButton}
                onClick={() => {
                  onRefresh();
                }}
              >
                {VideoToolStrings.Retake}
              </Button>
              {isRecorded && status !== 'stopping' ? (
                <Button
                  variant="text"
                  className={classes.submitButton}
                  onClick={() => {
                    setBlob(mediaBlobUrl);
                  }}
                >
                  Submit Clip
                </Button>
              ) : (
                <CircularProgress
                  sx={{
                    justifySelf: 'center',
                    color: '#ED2E38',
                  }}
                />
              )}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
