export const UploadPausedIcon = () => {
  return (
    <svg width="29" height="31" viewBox="0 0 29 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.82122 8.79687C3.16551 6.46849 5.17007 4.59102 7.58139 3.40188C9.99271 2.21275 12.7025 1.76536 15.3681 2.1163C18.0337 2.46723 20.5353 3.60072 22.5567 5.37343C24.5781 7.14614 26.0284 9.47845 26.7243 12.0754C27.4202 14.6724 27.3303 17.4174 26.4661 19.9633C25.6019 22.5092 24.0021 24.7417 21.8691 26.3784C19.7361 28.0151 17.1657 28.9826 14.4828 29.1584C11.8 29.3342 9.12526 28.7106 6.79687 27.3663"
        stroke="#DB5A10"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 23C11.1 23 12 22.1 12 21V11C12 9.9 11.1 9 10 9C8.9 9 8 9.9 8 11V21C8 22.1 8.9 23 10 23ZM16 11V21C16 22.1 16.9 23 18 23C19.1 23 20 22.1 20 21V11C20 9.9 19.1 9 18 9C16.9 9 16 9.9 16 11Z"
        fill="#DB5A10"
      />
    </svg>
  );
};
