import { FC } from 'react';
import { useStyles } from './UploadProgressBar.styles';
import { Box } from '@mui/system';
import { useTypedSelector } from '../../../store/store';
import { UploadProcessStep } from '../../../store/storeModels';
import { CircularProgress, Typography } from '@mui/material';
import { UploadProgressStrings } from '../../localization/en';
import { SuccessUploadIcon } from '../../assets/SuccessUploadIcon';
// import { WeakWifiIcon } from '../../assets/WeakWifiIcon';
import { useBlockNavigation } from '../../hooks/useBlockNavigation';
import { RouteLeavingModal } from '../RouteLeavingModal/RouteLeavingModal';
import { UploadPausedIcon } from '../../assets/UploadPausedIcon';

interface Props {
  forceShowUploadComplete?: boolean;
}

export const UploadProgressBar: FC<Props> = ({ forceShowUploadComplete }) => {
  const classes = useStyles({ color: '#2D11AD' });
  const { currentStep, uploadingProgress, isUploadError, isResumingUpload } = useTypedSelector((state) => state.upload);
  const isUploadComplete = currentStep === UploadProcessStep.Uploaded || forceShowUploadComplete;
  const { confirmNavigation, cancelNavigation, pendingNavigation } = useBlockNavigation({
    shouldBlock: !isUploadComplete || false,
  });
  return (
    <Box className={classes.container}>
      <RouteLeavingModal
        open={!!pendingNavigation}
        onCancel={cancelNavigation}
        onConfirm={confirmNavigation}
        titleText="title text"
        contentText="content text"
      />
      <Box
        className={classes.progressWrapper}
        sx={{
          border: isUploadError ? `1.5px solid #B42318` : `1.5px solid ${'#2D11AD'} `,
          background: '#fff',
        }}
      >
        {!isUploadComplete ? (
          isUploadError ? (
            <Box className={classes.uploadErrorWrapper}>
              <UploadPausedIcon />
              <Box ml="16px">
                <Typography className={classes.uploadFailText}>{UploadProgressStrings.VideoUploadFailed}</Typography>
                {/* <Typography className={classes.weakConnectText}>
                  {UploadProgressStrings.WeakConnectivityDetected}
                </Typography> */}
              </Box>
            </Box>
          ) : (
            <Box display={'flex'}>
              <CircularProgress
                variant={isResumingUpload || !uploadingProgress ? 'indeterminate' : 'determinate'}
                value={uploadingProgress}
                sx={{
                  color: '#2D11AD',
                  marginRight: '19px',
                }}
                size={27}
              />
              <Box display="block">
                <Typography className={classes.uploadText}>{UploadProgressStrings.VideoUploadInProgress}</Typography>
                <Typography
                  className={classes.keepOpenText}
                  sx={{
                    color: '#475467',
                  }}
                >
                  {UploadProgressStrings.KeepThisTabOpen}
                </Typography>
              </Box>
            </Box>
          )
        ) : (
          <>
            <SuccessUploadIcon color={'#2D11AD'} />
            <Box ml="16px">
              <Typography className={classes.uploadCompleteText}>{UploadProgressStrings.VideoUploadComplete}</Typography>
              <Typography className={classes.thanksText}>{UploadProgressStrings.ThanksForUploading}</Typography>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};
