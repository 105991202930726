import { Box, Button, Container, Input, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { ChangeEvent, FC, useCallback, useEffect, useRef, useState } from 'react';
import { trackEvent } from '../../../utils/analytics/analytics';
import { AuthInitialFormValues } from '../../common/commonTypes';
import { getError } from '../../common/components/AuthForm/AuthForm.helper';
import { DeactivatedUserModal } from '../../common/components/DeactivatedUserModal/DeactivatedUserModal';
import { ExistingUserModal } from '../../common/components/ExistingUserModal/ExistingUserModal';
import { PhoneFieldInternational } from '../../common/components/FormComponents/PhoneFieldInternational/PhoneFieldInternational';
import { UploadProgressBar } from '../../common/components/UploadProgressBar/UploadProgressBar';
import { authFormInitialValues, EventNames } from '../../common/constants/constants';
import { SignUpScreenStrings } from '../../common/localization/en';
import { useQuery } from '../../services/hooks/useQuery';
import { LoginWithPhoneNumber, requestMergeUser, resetMeError, updateUserRequest } from '../../store/slices/me';
import { setPendingUploadedStory } from '../../store/slices/upload';
import { useAppDispatch, useTypedSelector } from '../../store/store';
import { getValidationSchema } from '../MagicLinkPage/MagicLinkForm/MagicLinkForm.helper';
import { useVerificationStyles } from './VerificationScreen.styles';
import { ReCaptcha, ReCaptchaRefType } from '../../common/components/FormComponents/ReCAPTCHA/ReCaptcha';

interface VerificationScreenProps {
  captchaResponse?: string;
}

const VerificationScreen: FC<VerificationScreenProps> = ({}) => {
  const styles = useVerificationStyles();
  const queries = useQuery();
  const dispatch = useAppDispatch();
  const { account } = useTypedSelector((state) => state.account);
  const { currentCampaign, primaryCampaign } = useTypedSelector((state) => state.rewards);
  const {
    account: { id: accountId },
  } = useTypedSelector((state) => state.account);
  const {
    registerErrorType,
    phoneNumber: mePhoneNumber,
    id,
    firstName: storeFirstName,
    lastName: storeLastName,
    phoneNumber: storePhoneNumber,
  } = useTypedSelector((state) => state.me);
  const { email, firstName, phoneNumber, lastName } = {
    email: queries.get('svIdent') || '',
    firstName: queries.get('svUn')?.split(' ')[0] || storeFirstName || '',
    lastName: queries.get('svUn')?.split(' ')[1] || storeLastName || '',
    phoneNumber: queries.get('phone') || mePhoneNumber || '',
  };

  const [disabledSubmitButton, setDisabledSubmitButton] = useState(false);
  const [isDeactivatedUserModalOpen, setIsDeactivatedUserModalOpen] = useState(false);
  const [isExistingUserModalOpen, setIsExistingUserModalOpen] = useState(false);

  const [isVerificationComplete, setIsverificationComplete] = useState(!!storePhoneNumber || false);

  const [captcha, setCaptcha] = useState<string | null>('');
  const [isEnterpriseCaptcha, setIsEnterpriseCaptcha] = useState(true);
  const ecaptchaRef = useRef<ReCaptchaRefType>(null);

  const validateEmail = currentCampaign ? !!currentCampaign?.emailRequired : !!primaryCampaign?.emailRequired;

  const initValues: AuthInitialFormValues = {
    ...authFormInitialValues,
    email,
    firstName,
    lastName,
    phoneNumber: mePhoneNumber,
  };

  const formikProps = {
    initialValues: initValues,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: getValidationSchema({
      phoneNumber: true,
      firstName: false,
      lastName: false,
      email: validateEmail,
    }),
    onSubmit: async (values: AuthInitialFormValues) => {
      await onSubmitHandler(values);
    },

    onReset: () => {
      setDisabledSubmitButton(false);
    },
  };

  const formik = useFormik({
    ...formikProps,
    validationSchema: getValidationSchema({
      phoneNumber: true,
      firstName: false,
      lastName: false,
      email: validateEmail,
    }),
  });
  const fullName =
    formik.values.firstName && formik.values.lastName
      ? `${formik.values.firstName} ${formik.values.lastName}`
      : formik.values.firstName || '';

  const onSubmitHandler = useCallback(
    async (values: AuthInitialFormValues) => {
      const enterpriseCaptchaResponse = isEnterpriseCaptcha ? await ecaptchaRef.current?.executeAsync() : null;
      const captchaResponse = enterpriseCaptchaResponse || captcha || '';
      const magicLinkQueryParams = {
        accountId,
        isEnterpriseCaptcha: isEnterpriseCaptcha,
        phoneNumber: values.phoneNumber || phoneNumber,
        captchaResponse: captchaResponse,
        details: {
          email: null,
          firstName: values.firstName,
          lastName: values.lastName,
        },
      };

      setDisabledSubmitButton(true);

      dispatch(resetMeError());

      const isNewUserFlow = id !== '';
      const resultType = isNewUserFlow
        ? ((
            await dispatch(
              updateUserRequest({
                phoneNumber: values.phoneNumber,
                email: email || null,
                userId: id,
                firstName: values.firstName,
                lastName: values.lastName,
              }),
            )
          ).type as string)
        : null;

      const isPhoneAlreadyExists = resultType && resultType.includes('rejected');
      const isUpdateSuccess = resultType && resultType.includes('fulfilled');

      if (isUpdateSuccess) {
        setIsverificationComplete(true);
        return;
      }

      if (!isPhoneAlreadyExists) {
        dispatch(setPendingUploadedStory(null));
      }
      let token = '';
      let userId = '';
      if (isPhoneAlreadyExists) {
        token = localStorage.getItem('authToken') || '';
        userId = id;
      }

      dispatch(LoginWithPhoneNumber(magicLinkQueryParams)).then((response: any) => {
        if (isPhoneAlreadyExists) {
          const id = response?.payload?.user?.id;
          dispatch(
            requestMergeUser({
              //@ts-ignore
              id: id || '',
              accessToken: token || '',
              userToMerge: userId,
            }),
          );
        }
      });

      // : await requestSignInVerificationCode(magicLinkQueryParams);
    },
    [accountId, phoneNumber, firstName, lastName, dispatch, id, email],
  );

  useEffect(() => {
    trackEvent({
      action: EventNames.Phone_Verification_Page,
      location: window.location.href,
      accountId: account.id,
      userId: id,
    });
  }, [account.id, id]);

  useEffect(() => {
    if (registerErrorType === 'userAlreadyExists') {
      setIsExistingUserModalOpen(true);
      dispatch(resetMeError());
    }
  }, [dispatch, registerErrorType]);

  const handlePhoneChange = (val: string) => {
    formik.setFieldValue('phoneNumber', '+' + val);
  };

  const handleTrackPhoneNumberBlur = () => {
    trackEvent({
      action: EventNames.Signup_Phone_Typed,
      location: window.location.href,
      accountId: account.id,
      phoneNumber: formik.values.phoneNumber,
      firstName: formik.values.firstName,
      lastName: formik.values.lastName,
      email: formik.values.email,
      form: 'verification_screen',
    });
  };

  const trackCountryCodeChange = () => {
    trackEvent({
      action: EventNames.Phone_Country_Select,
      location: window.location.href,
      accountId: account.id,
      firstName: formik.values.firstName,
      lastName: formik.values.lastName,
      email: formik.values.email,
      phoneNumber: formik.values.phoneNumber,
      form: 'verification_screen',
    });
  };

  const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldTouched(event.target.name);
    formik.handleChange(event);
  };

  const getErrorHandler = (name: string, disabled?: boolean) => {
    if (disabled) {
      return formik.values[name] ? formik.errors[name] : '';
    }
    return getError(name, formik.touched, formik.errors);
  };

  const sendVerificationCodeBtnDisabled =
    disabledSubmitButton || formik.isSubmitting || !!formik.errors.phoneNumber || !formik.dirty || !!formik.errors.email;

  const onCaptchaChange = (value: string | null) => {
    setCaptcha(value);

    if (!value) {
      setIsEnterpriseCaptcha(false);
    }
  };

  return (
    <Box>
      <Container
        sx={{
          position: 'absolute',
          zIndex: 2,
          transform: `translateX(-50%)`,
          marginTop: '-20px',
        }}
        className={styles.Container}
      >
        <Box className={styles.progressBar}>
          <UploadProgressBar />
        </Box>
        <DeactivatedUserModal
          isOpen={isDeactivatedUserModalOpen}
          onClose={() => {
            setIsDeactivatedUserModalOpen(false);
          }}
        />
        {/* <VerificationModal
          modalType={modal}
          phoneNumber={formik.values.phoneNumber}
          setModalType={setModal}
          codeId={codeId}
          onBackClick={() => {}}
          accountId={accountId}
        /> */}
        <ExistingUserModal
          isOpen={isExistingUserModalOpen}
          onClose={() => setIsExistingUserModalOpen(false)}
          phoneNumber={formik.values.phoneNumber}
          goToSignup={() => {}}
          trackProperties={{ accountId, phoneNumber, name: fullName, email }}
        />
        {!isVerificationComplete && (
          <form
            className={styles.form}
            onSubmit={formik.handleSubmit}
            style={{
              bottom: window.innerHeight < 615 ? '140px' : '180px',
            }}
          >
            <Typography className={styles.formLabel}>
              {'Let’s make sure you get the credit you deserve for your video.'}
            </Typography>
            <Box display={'flex'} gap="10px">
              <Input
                className={styles.input}
                value={formik.values.firstName}
                onChange={handleFieldChange}
                disableUnderline={true}
                name="firstName"
                placeholder={SignUpScreenStrings.First}
                onBlur={formik.handleBlur}
              />

              <Input
                className={styles.input}
                value={formik.values.lastName}
                onChange={handleFieldChange}
                disableUnderline={true}
                name="lastName"
                placeholder={SignUpScreenStrings.Last}
                onBlur={formik.handleBlur}
              />
            </Box>
            <PhoneFieldInternational
              onChange={handlePhoneChange}
              name="phoneNumber"
              value={formik.values.phoneNumber || ''}
              error={formik.errors.phoneNumber}
              placeholder={'Enter Phone Number'}
              className={styles.textField}
              showErrorOnBlur={true}
              setTouched={() => {
                formik.setFieldTouched('phoneNumber', true);
                handleTrackPhoneNumberBlur();
              }}
              trackCountryCodeChange={trackCountryCodeChange}
              isOutlinedForLightTheme={false}
              inputFontSize="clamp(16px, 4.4vw, 24px)"
            />
            {validateEmail && (
              <Box style={{ marginTop: '10px' }}>
                <Input
                  className={styles.input}
                  value={formik.values.email}
                  onChange={handleFieldChange}
                  disableUnderline={true}
                  error={!!getErrorHandler('email', true)}
                  name="email"
                  placeholder={'Enter an email'}
                  onBlur={formik.handleBlur}
                />
                {getErrorHandler('email', true) && (
                  <Typography className={styles.errorText}>{formik.errors.email}</Typography>
                )}
              </Box>
            )}
            {/* <Typography className={styles.messageRateLabel}>{AuthFormStrings.WellSentYouCode}</Typography> */}
            <Button
              classes={{ disabled: styles.DisabledSubmitButton }}
              className={styles.AuthSubmitBtn}
              sx={{
                height: '48px',
                borderRadius: '12px',
              }}
              variant="contained"
              fullWidth
              disabled={sendVerificationCodeBtnDisabled}
              type="submit"
            >
              Here’s My Info!
            </Button>
            {/* <Typography className={styles.messageRateText} color={'#767676'}>
            {AuthFormStrings.MessageRate}
          </Typography> */}
          </form>
        )}
        {isVerificationComplete && <Box></Box>}
        <Box display={'block'} className={styles.capthaWrapper}>
          <ReCaptcha
            onChange={onCaptchaChange}
            key="captcha"
            ref={ecaptchaRef}
            onError={() => {
              setIsEnterpriseCaptcha(false);
            }}
            onExpired={() => {
              setIsEnterpriseCaptcha(false);
            }}
            isEnterprise={isEnterpriseCaptcha}
            onClick={() => {
              trackEvent({ action: EventNames.Captcha_Checkbox_Click, accountId });
            }}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default VerificationScreen;
