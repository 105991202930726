import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { StoryEndpoints, UploadEndpoints } from '../../api/endpoints';
// import { StoryDetails } from '../../api/models/auth';
import { StoriesApiModel } from '../../api/models/stories';
import { UploadUrlApiModel } from '../../api/models/upload';
import { Frame } from '../../common/components/UploadedVideoPreview/SelectThumbnailModal/SelectThumbnailModal';
import { httpClient } from '../../services/httpClient/httpClient';
import { UploadProcessDTO, UploadProcessStep, UploadIteration } from '../storeModels';

export const uploadInitialState: UploadProcessDTO = {
  currentStep: UploadProcessStep.UploadVideo,
  cellPhone: '',
  email: '',
  handle: '',
  password: '',
  uploadingProgress: 0,
  supportedMimeType: 'video/mp4',
  fileSelected: false,
  thumbnailFrameurl: null,
  pendingUploadedStory: null,
  uploadingIterations: [],
  sendSMSNotification: false,
  showRecord: false,
  isUploadError: false,
  isResumingUpload: false,
  isUploadInprogress: false,
};

export const createUploadUrl = async (mimeType?: string) => {
  try {
    return httpClient.post<undefined, UploadUrlApiModel>({
      url: `${UploadEndpoints.CreateUploadUrl}?mimeType=${mimeType}&resumable=true`,
      requiresToken: true,
    });
  } catch (error) {
    console.log('uploaded');
    return console.log(error);
  }
};

interface ResumableUploadUrlApiModel {
  resumableUploadUrl: string;
  contentType: string;
}

export const createResumableUploadUrl = async (mimeType?: string) => {
  try {
    return httpClient.post<undefined, ResumableUploadUrlApiModel>({
      url: `${UploadEndpoints.CreateResumableAploadUrl}?mimeType=${mimeType}&origin=${window.location.origin}`,
      requiresToken: true,
    });
  } catch (error) {
    console.log('uploaded');
    return console.log(error);
  }
};

export const createUploadThumbnailUrl = async (mimeType?: string) => {
  try {
    return httpClient.post<undefined, UploadUrlApiModel>({
      url: `${UploadEndpoints.CreateThumbnailUploadUrl}?mimeType=${mimeType}`,
      requiresToken: true,
    });
  } catch (error) {
    console.log('uploaded');
    return console.log(error);
  }
};

interface UploadVideoRequest {
  options: UploadUrlApiModel;
  data: string;
  onUploadProgress?: (event: ProgressEvent) => void;
}

type UploadThumbnailRequest = Omit<UploadVideoRequest, 'onUploadProgress'>;

export const uploadVideo = async ({ options, data, onUploadProgress }: UploadVideoRequest) => {
  if (!data) {
    return;
  }
  try {
    // const shouldFail = Math.floor(Math.random() * 2);
    // if (shouldFail) {
    //   // throw error to simulate upload fail
    //   throw { message: 'issue here' };
    // }

    const file = await fetch(data).then((r) => r.blob());

    const res = await axios.put<typeof options, undefined>(options.url, file, {
      headers: options.headers,
      onUploadProgress,
    });
    console.log(res);
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const uploadThumbnail = createAsyncThunk(
  'upload/uploadThumbnail',
  async ({ options, data }: UploadThumbnailRequest) => {
    if (!data) {
      return;
    }
    try {
      const file = await fetch(data).then((r) => r.blob());
      return await axios.put<typeof options, undefined>(options.url, file, {
        headers: options.headers,
      });
    } catch (error) {
      console.log(error);
    }
  },
);

interface UploadVideoAsFileRequest {
  options: UploadUrlApiModel;
  data: File;
  onUploadProgress?: (event: ProgressEvent) => void;
}

interface initialPostRequest {
  options: UploadUrlApiModel;
}

export const initialPost = async ({ options }: initialPostRequest) => {
  try {
    const res = await axios.post<typeof options, { status: number; statusText: string }>(options.url, null, {
      headers: options.headers,
    });

    return { status: res.status, statusText: res.statusText };
  } catch (error) {
    throw error;
  }
};

export const uploadVideoAsFile = async ({ options, data, onUploadProgress }: UploadVideoAsFileRequest) => {
  if (!data) {
    return;
  }
  try {
    // const shouldFail = Math.floor(Math.random() * 2);
    // if (shouldFail) {
    //   // throw error to simulate upload fail
    //   throw { message: 'issue here' };
    // }
    const res = await axios.post<typeof options, { status: number; statusText: string }>(options.url, data, {
      headers: options.headers,
      onUploadProgress,
    });
    console.log('upload response', res);

    return { status: res.status, statusText: res.statusText };
  } catch (error) {
    throw error;
  }
};

export const uploadVideoUnsuportedFile = createAsyncThunk(
  'upload/uploadVideoUnsupportedFile',
  async ({ options, data, onUploadProgress }: UploadVideoAsFileRequest) => {
    if (!data) {
      return;
    }
    try {
      const res = await axios.put<typeof options, undefined>(options.url, data, {
        headers: options.headers,
        onUploadProgress,
      });
      console.log(res);
      return res;
    } catch (error) {
      console.log(error);
    }
  },
);

export interface UploadStoryDetails {
  metaTitle: string;
  metaDescription: string;
  videoText: string;
  ctaBtnText: string;
  ctaBtnLinkUrl: string | null;
  videoMessage: string;
}
export interface CreateStoryRequest {
  categoryId: string;
  url: string;
  thumbnailUrl: string;
  altThumbnailUrl: string; // thumbnail without company logo
  details: UploadStoryDetails;
  isPublic: boolean;
  sendSMSNotification?: boolean;
  campaignId?: string | null;
  duration?: number;
  webappRecorded?: boolean;
}
export const CreateStory = createAsyncThunk('upload/createStory', async (payload: CreateStoryRequest) => {
  return await httpClient.post<CreateStoryRequest, StoriesApiModel>({
    url: StoryEndpoints.CreateStory,
    payload,
    requiresToken: true,
  });
});

const uploadSlice = createSlice({
  name: 'upload',
  initialState: uploadInitialState,
  reducers: {
    changeStep(state, action: PayloadAction<UploadProcessStep>) {
      state.currentStep = action.payload;
    },
    setthumbnailFrameurl(state, action: PayloadAction<Frame>) {
      state.thumbnailFrameurl = action.payload;
    },
    setCellPhone(state, action: PayloadAction<string>) {
      state.cellPhone = action.payload;
    },
    setEmail(state, action: PayloadAction<string>) {
      state.email = action.payload;
    },
    setHandle(state, action: PayloadAction<string>) {
      state.handle = action.payload;
    },
    setPassword(state, action: PayloadAction<string>) {
      state.password = action.payload;
    },
    setProgress(state, action: PayloadAction<number>) {
      if (action.payload > 95) {
        return;
      }
      state.uploadingProgress = action.payload;
    },
    setMimeType(state, action: PayloadAction<string>) {
      state.supportedMimeType = action.payload;
    },
    setFileSelected(state, action: PayloadAction<boolean>) {
      state.fileSelected = action.payload;
    },
    setPendingUploadedStory(state, action: PayloadAction<UploadProcessDTO['pendingUploadedStory']>) {
      state.pendingUploadedStory = action.payload;
    },
    toggleSmsNotification(state) {
      state.sendSMSNotification = !state.sendSMSNotification;
    },
    reset: () => uploadInitialState,
    addUploadingIteration: (state, action: PayloadAction<UploadIteration>) => {
      state.uploadingIterations.push(action.payload);
    },
    resetUploadingIterations: (state) => {
      state.uploadingIterations = uploadInitialState.uploadingIterations;
    },
    processRecord: (state) => {
      state.showRecord = true;
    },
    resetProcessRecord: (state) => {
      state.showRecord = uploadInitialState.showRecord;
    },
    setUploadError(state, action: PayloadAction<boolean>) {
      state.isUploadError = action.payload;
    },
    setIsResumingUpload(state, action: PayloadAction<boolean>) {
      state.isResumingUpload = action.payload;
    },
    setIsUploadInPorgress(state, action: PayloadAction<boolean>) {
      state.isUploadInprogress = action.payload;
    },
  },
  extraReducers: (reducersBuilder) => {
    // reducersBuilder.addCase(uploadVideo.pending, (state) => {
    //   state.currentStep = UploadProcessStep.VideoUploading;
    // });
    // reducersBuilder.addCase(uploadVideoAsFile.pending, (state) => {
    //   // state.currentStep = UploadProcessStep.VideoUploading;
    // });
    reducersBuilder.addCase(uploadVideoUnsuportedFile.pending, (state) => {
      state.currentStep = UploadProcessStep.PrepareVideo;
    });
    reducersBuilder.addCase(uploadVideoUnsuportedFile.fulfilled, (state) => {
      state.uploadingProgress = 100;
      state.isResumingUpload = false;
    });

    reducersBuilder.addCase(CreateStory.rejected, (state) => {
      state.isUploadError = true;
    });

    reducersBuilder.addCase(CreateStory.fulfilled, (state) => {
      state.currentStep = UploadProcessStep.Uploaded;
      state.uploadingProgress = 100;
    });
  },
});

export const {
  changeStep,
  setEmail,
  setCellPhone,
  setHandle,
  setPassword,
  toggleSmsNotification,
  reset,
  setProgress,
  setMimeType,
  setFileSelected,
  setthumbnailFrameurl,
  setPendingUploadedStory,
  resetUploadingIterations,
  addUploadingIteration,
  processRecord,
  resetProcessRecord,
  setUploadError,
  setIsResumingUpload,
  setIsUploadInPorgress,
} = uploadSlice.actions;
export default uploadSlice.reducer;
