import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    width: '100%',
    maxWidth: '540px',
    margin: '0 auto',
    position: 'relative',
  },
  crispIcon: {
    position: 'absolute',
    bottom: '50px',
    right: '15px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '51px',
    height: '38px',
    zIndex: '1001',
    [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
      right: '5px',
    },
  },
  text: {
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '22px',
    color: theme.palette.common.black,
    position: 'absolute',
    top: '8px',
  },
  crispIconRight: {
    transform: 'translateX(250px)',
  },
  crispIconMobile: {
    top: '20px',
    transform: 'translateX(0)',
    right: '10px',
    height: '40px',
    cursor: 'pointer',
    borderTopLeftRadius: '20px',
    borderBottomLeftRadius: '20px',
    borderBottomRightRadius: '20px',
    color: theme.palette.common.black,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: '600',
  },
  marginTop: {
    top: '60px',
  },
  marginTop90: {
    top: '90px',
  },
  videoPosition: {
    top: '40px',
  },
  bottomPosition: {
    position: 'absolute',
    bottom: '45px',
    right: '20px',
  },
  hidden: {
    display: 'none',
  },
}));
