import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthRequestBody, MeApiModel, UserModel } from '../../api/models/auth';
import { httpClient } from '../../services/httpClient/httpClient';
import { AuthEndpoints, getApiUrlForId } from '../../api/endpoints';
import {
  UserModelDTO,
  UserEmailQueryParams,
  VerificationCodeQueryParams,
  MagicLinkRequestQueryParams,
  MergeUserQueryParams,
} from '../storeModels';

export const initialMeState: UserModelDTO = {
  accountId: '',
  id: '',
  name: '',
  firstName: '',
  lastName: '',
  email: '',
  photoUrl: '',
  phoneNumber: '',
  instagramHandle: '',
  points: 0,
  campaignPoints: 0,
  returnUrl: '',
  categoryId: '',
  isLoading: false,
  error: false,
  svuid: '',
  svTl: '',
  scaleoAffiliateId: null,
  displayName: '',
  latestRegisteredToken: '',
  status: '',
  defaultVideoDetails: null,
  registerErrorType: '',
  phoneNumberVerified: false,
};

export const loginRequest = createAsyncThunk(
  'me/login',
  async (values: { password?: string; email: string; accountId: string }, { rejectWithValue }) => {
    try {
      return await httpClient.login<AuthRequestBody>({
        url: AuthEndpoints.LoginUser,

        payload: values,

        requiresToken: false,
      });
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.status);
    }
  },
);

export const registerRequest = createAsyncThunk('me/register', async (values: AuthRequestBody, { rejectWithValue }) => {
  try {
    return await httpClient.register<AuthRequestBody>({
      url: AuthEndpoints.RegisterUser,

      payload: values,

      requiresToken: false,
    });
  } catch (error) {
    // @ts-ignore
    return rejectWithValue(error.response.data.status);
  }
});

export const loginUnverified = createAsyncThunk(
  'me/login-unverified',
  async (values: AuthRequestBody, { rejectWithValue }) => {
    try {
      const { captcha, isEnterpriseCaptcha, ...remain } = values;
      return await httpClient.register<AuthRequestBody>({
        url: values?.isEnterpriseCaptcha
          ? `${AuthEndpoints.LoginUnverified}?eCaptchaResponse=${values?.captcha}`
          : `${AuthEndpoints.LoginUnverified}?captchaResponse=${values?.captcha}`,
        payload: remain,
        requiresToken: false,
      });
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.status);
    }
  },
);

export const authWithPartnerToken = createAsyncThunk(
  'me/authWithPartnerToken',
  async (values: { token: string; accountId: string }, { rejectWithValue }) => {
    try {
      return await httpClient.register<{ token: string; accountId: string }>({
        url: AuthEndpoints.AuthWithPartnerToken,

        payload: values,

        requiresToken: false,
      });
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.status);
    }
  },
);

interface UpdateUserRequestParams extends AuthRequestBody {
  userId: string;
}

export const updateUserRequest = createAsyncThunk(
  'me/updateUser',
  async (values: UpdateUserRequestParams, { rejectWithValue }) => {
    try {
      return await httpClient.put<AuthRequestBody, UserModel>({
        url: getApiUrlForId(AuthEndpoints.UpdateUser, values.userId),

        payload: values,

        requiresToken: true,
      });
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getUserRequest = createAsyncThunk('me/getUser', async (_options: string, { rejectWithValue }) => {
  try {
    return await httpClient.get<undefined, UserModel>({
      url: getApiUrlForId(AuthEndpoints.GetUser, _options),

      requiresToken: true,
    });
  } catch (error) {
    // @ts-ignore
    return rejectWithValue(error.response.data.message);
  }
});

/* unused
export const etsConnect = createAsyncThunk(
  'me/etsConnect',
  async (_options: { etsToken: string; accountId: string }, { rejectWithValue }) => {
    try {
      return await httpClient.login<{ etsToken: string; accountId: string }>({
        url: AuthEndpoints.ETSConnect,
        payload: _options,
        requiresToken: false,
      });
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);
*/

export const validatePWRToken = async (_options: { token: string }) => {
  try {
    return await httpClient.post<{ token: string }, { isValid: boolean }>({
      url: AuthEndpoints.ValidatePwrToken,
      payload: _options,
      requiresToken: false,
    });
  } catch (error) {
    return { isValid: false };
  }
};

export const loginWithMagicLink = createAsyncThunk(
  'me/loginWithMagicLink',
  async (_options: string, { rejectWithValue }) => {
    try {
      const response = (await httpClient.post({
        url: `${AuthEndpoints.LoginWithMagicLink}/${_options}`,
        requiresToken: false,
      })) as MeApiModel;

      localStorage.setItem('authToken', response.accessToken);
      localStorage.setItem('refreshToken', response.refreshToken);
      localStorage.setItem('userId', response.user.id);
      localStorage.setItem('loginWithMagicLink', 'true');

      return response as MeApiModel;
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const requestMagicLink = async (_options: MagicLinkRequestQueryParams) => {
  try {
    const result = await httpClient.post({
      url: AuthEndpoints.RequestMagicLink,
      payload: _options,
      requiresToken: false,
    });
    return result;
  } catch (error) {
    return { isValid: false };
  }
};

export const loginWithVerificationCode = createAsyncThunk(
  'me/loginWithVerificationCode',
  async (
    _options: {
      codeId: string;
      verificationCode: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const response = (await httpClient.login<MeApiModel>({
        url: `${getApiUrlForId(AuthEndpoints.LoginWithVerificationCode, _options.codeId)}?verificationCode=${
          _options.verificationCode
        }`,
        requiresToken: false,
      })) as MeApiModel;

      return response as MeApiModel;
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const requestSignInVerificationCode = async (_options: VerificationCodeQueryParams) => {
  try {
    const captcha = _options.captchaResponse ? `?captchaResponse=${_options.captchaResponse}` : '';

    const result = await httpClient.post<VerificationCodeQueryParams, { id: string; errorStatus?: string }>({
      url: `${AuthEndpoints.RequestSignInCode}${captcha}`,
      payload: _options,
      requiresToken: true,
    });
    return result;
  } catch (error) {
    //@ts-ignore
    const status = error.response.status;
    return { id: '', errorStatus: status };
  }
};

export const requestMergeUser = createAsyncThunk('me/requestMergeUser', async (_options: MergeUserQueryParams) => {
  try {
    const { id, ...rest } = _options;
    const response = await httpClient.post<any, { success: boolean }>({
      url: `${getApiUrlForId(AuthEndpoints.MergeUser, id)}`,
      payload: { ...rest },
      requiresToken: true,
    });

    return response;
  } catch (error) {
    // @ts-ignore
    return rejectWithValue(error.response.data.message);
  }
});

//LoginWithPhoneNumber

interface LoginWithPhoneNumber extends VerificationCodeQueryParams {
  isEnterpriseCaptcha: boolean;
}

export const LoginWithPhoneNumber = createAsyncThunk(
  'me/LoginWithPhoneNumber',
  async (_options: LoginWithPhoneNumber, { rejectWithValue }) => {
    try {
      const captcha = _options.captchaResponse
        ? `?${_options.isEnterpriseCaptcha ? 'eCaptchaResponse' : 'CaptchaResponse'}=${_options.captchaResponse}`
        : '';
      const result = await httpClient.post<VerificationCodeQueryParams, MeApiModel>({
        url: `${AuthEndpoints.LoginWithPhoneNumber}${captcha}`,
        payload: _options,
        requiresToken: true,
      });
      localStorage.setItem('authToken', result.accessToken);
      localStorage.setItem('refreshToken', result.refreshToken);
      localStorage.setItem('userId', result.user.id);
      return result;
    } catch (error) {
      //@ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const requestLoginVerificationCode = async (_options: VerificationCodeQueryParams, requiresToken: boolean) => {
  try {
    const captcha = _options.captchaResponse ? `?captchaResponse=${_options.captchaResponse}` : '';
    const result = await httpClient.post<VerificationCodeQueryParams, { id: string; errorStatus?: string }>({
      url: `${AuthEndpoints.RequestLoginCode}${captcha}`,
      payload: _options,
      requiresToken,
    });
    return result;
  } catch (error) {
    //@ts-ignore
    const status = error.response.status;
    return { id: '', errorStatus: status };
  }
};

export const validateCell = async (_options: { phoneNumber: string; accountId: string }) => {
  try {
    const result = await httpClient.post<{ phoneNumber: string; accountId: string }, { success: boolean }>({
      url: AuthEndpoints.ValidateCell,
      payload: _options,
      requiresToken: false,
    });
    return result;
  } catch (error) {
    return { success: false };
  }
};

const meSlice = createSlice({
  name: 'me',
  initialState: initialMeState,
  reducers: {
    updateMeWithEmailQueryParams(state, action: PayloadAction<UserEmailQueryParams>) {
      state.email = action.payload.email || state.email;
      state.name = action.payload.name || state.name;
      state.firstName = action.payload.firstName || state.firstName;
      state.lastName = action.payload.lastName || state.lastName;
      state.phoneNumber = action.payload.phoneNumber || state.phoneNumber;
      state.returnUrl = action.payload.returnUrl || state.returnUrl;
      state.categoryId = action.payload.categoryId || state.categoryId;
    },
    reset: (state) => {
      return {
        ...initialMeState,
        latestRegisteredToken: state.latestRegisteredToken,
      };
    },
    resetMeError: (state) => {
      state.error = false;
      state.registerErrorType = '';
    },
    setSVUId: (state, { payload }) => {
      state.svuid = payload.id;
    },
    setSVTl: (state, { payload }) => {
      state.svTl = payload.svTl;
    },
    setLatestRegisteredToken: (state, { payload }) => {
      state.latestRegisteredToken = payload.token;
    },
    resetLatestRegisteredToken: (state) => {
      state.latestRegisteredToken = '';
    },
  },
  extraReducers: (reducerBuilder) => {
    reducerBuilder.addCase(registerRequest.pending, (state) => {
      state.isLoading = true;
    });
    reducerBuilder.addCase(registerRequest.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.email = payload.user.email;
      state.name = payload.user.name;
      state.firstName = payload.user.firstName;
      state.lastName = payload.user.lastName;
      state.id = payload.user.id;
      state.accountId = payload.user.accountId;
      state.photoUrl = payload.user.photoUrl;
      state.phoneNumber = payload.user.phoneNumber;
      state.points = payload.user.points;
      state.instagramHandle = payload.user.instagramHandle;
      state.returnUrl = payload.user.returnUrl;
      state.scaleoAffiliateId = payload.user.scaleoAffiliateId;
      state.displayName = payload.user.displayName;
      state.status = payload.user.status;
      state.defaultVideoDetails = payload.user.defaultVideoDetails;
      state.campaignPoints = payload.user.campaignPoints;
      state.phoneNumberVerified = payload.user.phoneNumberVerified;
    });
    reducerBuilder.addCase(updateUserRequest.pending, (state) => {
      state.isLoading = true;
    });
    //authWithPartnerToken

    reducerBuilder.addCase(authWithPartnerToken.pending, (state) => {
      state.isLoading = true;
    });
    reducerBuilder.addCase(authWithPartnerToken.rejected, (state) => {
      state.isLoading = false;
      state.error = true;
    });
    reducerBuilder.addCase(authWithPartnerToken.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.email = payload.user.email;
      state.name = payload.user.name;
      state.firstName = payload.user.firstName;
      state.lastName = payload.user.lastName;
      state.id = payload.user.id;
      state.accountId = payload.user.accountId;
      state.photoUrl = payload.user.photoUrl;
      state.phoneNumber = payload.user.phoneNumber;
      state.points = payload.user.points;
      state.instagramHandle = payload.user.instagramHandle;
      state.returnUrl = payload.user.returnUrl;
      state.scaleoAffiliateId = payload.user.scaleoAffiliateId;
      state.displayName = payload.user.displayName;
      state.status = payload.user.status;
      state.defaultVideoDetails = payload.user.defaultVideoDetails;
      state.campaignPoints = payload.user.campaignPoints;
      state.phoneNumberVerified = payload.user.phoneNumberVerified;
    });
    reducerBuilder.addCase(updateUserRequest.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.email = payload.email;
      state.name = payload.name;
      state.firstName = payload.firstName;
      state.lastName = payload.lastName;
      state.id = payload.id;
      state.accountId = payload.accountId;
      state.photoUrl = payload.photoUrl;
      state.phoneNumber = payload.phoneNumber;
      state.points = payload.points;
      state.instagramHandle = payload.instagramHandle;
      state.returnUrl = payload.returnUrl;
      state.scaleoAffiliateId = payload.scaleoAffiliateId;
      state.displayName = payload.displayName;
      state.status = payload.status;
      state.defaultVideoDetails = payload.defaultVideoDetails;
      state.campaignPoints = payload.campaignPoints;
      state.phoneNumberVerified = payload.phoneNumberVerified;
    });
    reducerBuilder.addCase(loginRequest.pending, (state) => {
      state.isLoading = true;
    });
    reducerBuilder.addCase(loginRequest.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.email = payload.user.email;
      state.name = payload.user.name;
      state.firstName = payload.user.firstName;
      state.lastName = payload.user.lastName;
      state.id = payload.user.id;
      state.accountId = payload.user.accountId;
      state.photoUrl = payload.user.photoUrl;
      state.phoneNumber = payload.user.phoneNumber;
      state.points = payload.user.points;
      state.instagramHandle = payload.user.instagramHandle;
      state.returnUrl = payload.user.returnUrl;
      state.scaleoAffiliateId = payload.user.scaleoAffiliateId;
      state.displayName = payload.user.displayName;
      state.status = payload.user.status;
      state.defaultVideoDetails = payload.user.defaultVideoDetails;
      state.campaignPoints = payload.user.campaignPoints;
      state.phoneNumberVerified = payload.user.phoneNumberVerified;
    });
    reducerBuilder.addCase(getUserRequest.pending, (state) => {
      state.isLoading = true;
    });
    reducerBuilder.addCase(getUserRequest.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.email = payload.email;
      state.name = payload.name;
      state.firstName = payload.firstName;
      state.lastName = payload.lastName;
      state.id = payload.id;
      state.accountId = payload.accountId;
      state.photoUrl = payload.photoUrl;
      state.phoneNumber = payload.phoneNumber || '';
      state.points = payload.points;
      state.campaignPoints = payload.campaignPoints;
      state.instagramHandle = payload.instagramHandle;
      state.returnUrl = payload.returnUrl;
      state.scaleoAffiliateId = payload.scaleoAffiliateId;
      state.displayName = payload.displayName;
      state.status = payload.status;
      state.phoneNumberVerified = payload.phoneNumberVerified;
      if (payload.defaultVideoDetails) {
        state.defaultVideoDetails = {
          ...payload.defaultVideoDetails,
        };
      }
    });

    reducerBuilder.addCase(loginWithMagicLink.pending, (state) => {
      state.isLoading = true;
    });

    reducerBuilder.addCase(loginWithMagicLink.rejected, (state) => {
      state.isLoading = false;
    });

    reducerBuilder.addCase(loginWithMagicLink.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.email = payload.user.email;
      state.name = payload.user.name;
      state.firstName = payload.user.firstName;
      state.lastName = payload.user.lastName;
      state.id = payload.user.id;
      state.accountId = payload.user.accountId;
      state.photoUrl = payload.user.photoUrl;
      state.phoneNumber = payload.user.phoneNumber;
      state.points = payload.user.points;
      state.campaignPoints = payload.user.campaignPoints;
      state.instagramHandle = payload.user.instagramHandle;
      state.returnUrl = payload.user.returnUrl;
      state.scaleoAffiliateId = payload.user.scaleoAffiliateId;
      state.displayName = payload.user.displayName;
      state.status = payload.user.status;
      state.defaultVideoDetails = payload.user.defaultVideoDetails;
      state.phoneNumberVerified = payload.user.phoneNumberVerified;
    });

    //LoginWithPhoneNumber

    reducerBuilder.addCase(LoginWithPhoneNumber.pending, (state) => {
      state.isLoading = true;
    });

    reducerBuilder.addCase(LoginWithPhoneNumber.rejected, (state) => {
      state.isLoading = false;
    });

    reducerBuilder.addCase(LoginWithPhoneNumber.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.email = payload.user.email;
      state.name = payload.user.name;
      state.firstName = payload.user.firstName;
      state.lastName = payload.user.lastName;
      state.id = payload.user.id;
      state.accountId = payload.user.accountId;
      state.photoUrl = payload.user.photoUrl;
      state.phoneNumber = payload.user.phoneNumber;
      state.points = payload.user.points;
      state.campaignPoints = payload.user.campaignPoints;
      state.instagramHandle = payload.user.instagramHandle;
      state.returnUrl = payload.user.returnUrl;
      state.scaleoAffiliateId = payload.user.scaleoAffiliateId;
      state.displayName = payload.user.displayName;
      state.status = payload.user.status;
      state.defaultVideoDetails = payload.user.defaultVideoDetails;
      state.phoneNumberVerified = payload.user.phoneNumberVerified;
    });

    reducerBuilder.addCase(loginWithVerificationCode.pending, (state) => {
      state.isLoading = true;
      state.error = false;
    });

    reducerBuilder.addCase(loginWithVerificationCode.rejected, (state) => {
      state.isLoading = false;
      state.error = true;
    });

    reducerBuilder.addCase(loginWithVerificationCode.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = false;
      state.email = payload.user.email;
      state.name = payload.user.name;
      state.firstName = payload.user.firstName;
      state.lastName = payload.user.lastName;
      state.id = payload.user.id;
      state.accountId = payload.user.accountId;
      state.photoUrl = payload.user.photoUrl;
      state.phoneNumber = payload.user.phoneNumber;
      state.points = payload.user.points;
      state.campaignPoints = payload.user.campaignPoints;
      state.instagramHandle = payload.user.instagramHandle;
      state.returnUrl = payload.user.returnUrl;
      state.scaleoAffiliateId = payload.user.scaleoAffiliateId;
      state.displayName = payload.user.displayName;
      state.status = payload.user.status;
      state.defaultVideoDetails = payload.user.defaultVideoDetails;
      state.phoneNumberVerified = payload.user.phoneNumberVerified;
      localStorage.setItem('tos_checked', 'true');
    });

    reducerBuilder.addCase(loginUnverified.pending, (state) => {
      state.isLoading = true;
      state.error = false;
      state.registerErrorType = '';
    });

    reducerBuilder.addCase(loginUnverified.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = true;
      if (payload === 400) {
        state.registerErrorType = 'captchaError';
      }
      if (payload === 409) {
        state.registerErrorType = 'userAlreadyExists';
      }
    });

    reducerBuilder.addCase(loginUnverified.fulfilled, (state, { payload }) => {
      for (const key of Object.keys(initialMeState)) {
        if (key in payload.user) {
          //@ts-ignore
          state[key] = payload.user[key];
        }
      }
      state.isLoading = false;
      state.error = false;
      state.registerErrorType = '';
    });
    /* unused
      reducerBuilder.addCase(etsConnect.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.email = payload.user.email;
        state.name = payload.user.name;
        state.id = payload.user.id;
        state.accountId = payload.user.accountId;
        state.photoUrl = payload.user.photoUrl;
        state.phoneNumber = payload.user.phoneNumber || '';
        state.points = payload.user.points;
        state.instagramHandle = payload.user.instagramHandle;
        state.returnUrl = payload.user.returnUrl;
        state.scaleoAffiliateId = payload.user.scaleoAffiliateId;
        state.displayName = payload.user.displayName;
        state.status = payload.user.status;
        state.defaultVideoDetails = payload.user.defaultVideoDetails;
      });
      reducerBuilder.addCase(etsConnect.pending, (state) => {
        state.isLoading = true;
      });
      */
  },
});

export const { updateMeWithEmailQueryParams, reset, setSVUId, setSVTl, setLatestRegisteredToken, resetMeError } =
  meSlice.actions;

export default meSlice.reducer;
