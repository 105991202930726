import { FC } from 'react';
import { useStyles } from './UploadErrorNotification.styles';
import { Box } from '@mui/system';
import { Button, Typography } from '@mui/material';
import { UploadProgressStrings } from '../../localization/en';

interface Props {
  onRestartClick: () => void;
}

export const UploadErrorNotification: FC<Props> = ({ onRestartClick }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography className={classes.title} color={'#1A1A1A'}>
        {UploadProgressStrings.Hmmm}
      </Typography>
      <Typography className={classes.title} mt="20px" color={'#1A1A1A'}>
        {UploadProgressStrings.uploadInterrupted}
      </Typography>
      <Typography className={classes.subtitle} color={'#475467'}>
        {UploadProgressStrings.TapResume}
      </Typography>
      <Button className={classes.btn} onClick={onRestartClick}>
        {UploadProgressStrings.RestartUploadButton}
      </Button>
    </Box>
  );
};
