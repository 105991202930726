import { AuthInitialFormValues } from '../commonTypes';

export const cloudinaryUploadLink = 'https://api.cloudinary.com/v1_1/socialvenu/upload/';
export const maxSizeUploadedUserFile = 400000000;
export const twitterShareLink = 'https://twitter.com/intent/tweet?url=';
export const facebookShareLink = 'https://www.facebook.com/sharer/sharer.php?u=';
export const linkedinShareLink = 'https://www.linkedin.com/sharing/share-offsite/?url=';
export const pinterestShareLink = 'https://pinterest.com/pin/create/button/?url=';

export const urlMaskRegex = new RegExp(
  `((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)`,
);
export const simpleUrlMaskRegex = new RegExp(`^(http|https)://`);

export const TwilioSupportedCountries = [
  'us',
  'be',
  'fr',
  'gr',
  'ie',
  'mc',
  'no',
  'es',
  'de',
  'it',
  'se',
  'ua',
  'dk',
  'fi',
  'nl',
  'pt',
  'ch',
  'gb',
  'ar',
  'cl',
  'br',
  'eg',
  'za',
  'fj',
  'au',
  'nz',
  'am',
  'mx',
  'cr',
  'pr',
  'vi',
  'ca',
  'ni',
];

export interface DefaultPaginationValues {
  page: number;
  totalItems: number;
  totalPages: number;
  size: number;
  sort: string;
  sortByLastCreated: string;
  sortByFirstCreated: string;
}

export const defaultPageSizing = {
  tabletPageWidth: '100%',
  desktopPageWidth: '1128px',
  desktopContentWidth: '672px',
  mobilePageWidth: '100%',
};

export const defaultPagination = {
  page: 0,
  totalItems: 0,
  totalPages: 0,
  size: 10,
  sort: '',
  sortByLastCreated: 'createdAt,desc',
  sortByFirstCreated: 'createdAt,asc',
} as DefaultPaginationValues;

export const authFormInitialValues: AuthInitialFormValues = {
  name: '',
  email: '',
  password: '',
  confirmPassword: '',
  phoneNumber: '',
  photoUrl: '',
};

export const isIosChromeMobileBrowser = () => {
  return /CriOS/i.test(window.navigator.userAgent) && /iphone|ipod|ipad/i.test(window.navigator.userAgent);
};

export enum EventTypes {
  ButtonClick = 'Button Click',
  LinkClick = 'Link Click',
  VideoCreated = 'video created',
  FormSubmit = 'form submit',
  CarouselSwipe = 'Couarousel Swipe',
}

export enum SocialNetworks {
  Linkedin = 'Linkedin',
  Facebook = 'Facebook',
  Twitter = 'Twitter',
  Instagram = 'Instagram',
  Pinterest = 'Pinterest',
}

export enum Messages {
  Sms = 'sms',
  Email = 'email',
}
export enum EventActions {}
export enum EventNames {
  WelcomePopupClose = 'welcome popup -> close',
  WelcomePopupLearnMore = 'welcome popup -> learn more',
  WelcomePopupLetsGo = 'welcome popup -> lets go',
  WelcomePopupTOS = 'welcome popup -> terms of Use',
  SidebarOpen = 'side bar -> open',
  SidebarClose = 'side bar -> close',
  SideBarLogout = 'side bar -> logout',
  SideBarRoute = 'side bar route -> ', // email-us or live-chat
  SideBarCreateNew = 'side bar -> create new',
  SideBarVideos = 'side bar -> your videos',
  SidebarHowItWorks = 'side bar -> how it works',
  SideBarEmailUs = 'side bar -> email us',
  SideBarLiveChat = 'side bar -> live chat',
  SideBarTOS = 'side bar -> Terms of Use',
  TopBarProfileAvatar = 'top bar -> profile avatar',
  HomePageRecordVideo = 'home page -> record video',
  HomePageUploadVideo = 'home page -> upload video',
  HomePagePreviewSubmit = 'home page - preview -> submit',
  HomePagePreviewSubmitUploaded = 'home page - preview -> submit uploaded',
  HomePagePreviewReupload = 'home page - upload preview -> re-upload',
  HomePageUploadNext = 'home page - upload -> ', // Next or Time to Share
  HomePageWhereDoesMyContentGoes = 'home page - where does my content go ?',
  HomePageRerecord = 'home page - record preview -> re-record',
  LoginFormSignup = 'Login form -> sign up',
  LoginFormLogin = 'Login form -> login',
  LoginFormTOSLink = 'Login form -> TOS link',
  LoginFormShowPassword = 'Login form -> show password',
  LoginFormForgotPassword = 'Login form -> forgot password',
  LoginFormSubmit = 'Login form -> next / submit',
  LoginFormClose = 'Login form -> close',
  SharePageDownload = 'Share page -> download',
  SharePageCopyLink = 'Share page -> copy link',
  MyVideosPagePlayVideo = 'My videos page -> play video',
  MyVideosPagePlayVideoClick = 'My videos page -> play video click',
  SharePageToggleSubtitles = 'Share page -> toggle subtitles',
  StoryLinkDonate = 'Story link -> donate',
  StoryLinkPlayVideo = 'Story link -> play video',
  WelcomeVideoClick = 'welcome_video_click',
  StoryLinkToggleSubtitles = 'Story link -> toggle subtitles',
  CarouselSwipe = 'Carousel Swipe',
  VideoUploaded = ' video uploaded',
  SharePageVideoElementClick = 'share_page_click_on_video',
  SharePagePlayIconClick = 'share_page_click_play_icon',
  SharePageChangeThumbnailClickOpen = 'share_page_click_open_thumbnail_change_icon',
  SharePageChangeThumbnailClickClose = 'share_page_click_close_thumbnail_change_icon',
  SharePageChangeThumbnailClickSave = 'share_page_click_save_thumbnail',
  SharePageSmsShare = 'share_page_share_via_sms',
  SharePageFacebookShare = 'share_page_share_to_facebook',
  SharePageTwitterShare = 'share_page_share_to_twitter',
  SharePagePinterestShare = 'share_page_share_to_pinterest',
  SharePageSMSShare = 'share_page_share_to_message',
  SharePageEmailShare = 'share_page_share_to_email',
  LoginPage = 'login_page',
  SignUpPage = 'signup_page',
  SharePageInstagramDownload = 'share_page_share_to_instagram',
  SharePageTikTokDownload = 'share_page_share_to_tiktok',
  SharePageReelsDownload = 'share_page_share_to_reels',
  SharePageRedditDownload = 'share_page_share_to_reddit',
  SharePageLinkedInDownload = 'share_page_share_to_linkedin',
  SharePageRawDownload = 'share_page_share_to_download',
  FrameSelected = 'share_page_frame_selected',
  ThumbnailUpdateConfirm = 'share_page_thumbnail_update_confirm',
  ThumbnailUpdateCancel = 'share_page_thumbnail_update_cancel',
  SharePagePreviewNavigation = 'share_page_preview_navigation',
  Link = 'share_page_share_to_link',
  TopbarRewardProgress = 'top_bar.rewards_progress.click',
  ProfileRewardsCreateStory = 'propfile.reward.createStory.click',
  ProfileVideosShareClick = 'profile.videos.share.click',
  ProfileVideosCreateStory = 'profile.videos.create_story.click',

  Signup_Name_Typed = 'signup_first_name_typed',
  Signup_Last_Name_Typed = 'signup_last_name_typed',
  Signup_Email_Typed = 'signup_email_typed',
  Signup_Phone_Typed = 'signup_phone_typed',

  Phone_Country_Select = 'phone_country_select',

  Captcha_Checkbox_Click = 'captcha_checkbox_click',

  Captcha_Check_Failed = 'captcha_check_failed',
  Captcha_Check_Success = 'captcha_check_success',

  Signup_Button_Click = 'signup_button_click',

  Sv_Tos_Checkbox_Click = 'sv_tos_checkbox_click',

  Tb_Tos_Checkbox_Click = 'tb_tos_checkbox_click',
  Sv_Health_Tos_Checkbox_Click = 'sv_health_tos_checkbox_click',

  Sv_Tos_Link_Click = 'sv_tos_link_click',
  Sv_Privacy_Policy_Link_Click = 'sv_privacy_policy_link_click',

  Tb_Tos_Link_Click = 'tb_tos_link_click',
  Tb_Tos_Privacy_Policy_Click = 'tb_tos_privacy_policy_click',

  //SV_HEALTH
  Sv_Health_Tos_Link_Click = 'sv_health_tos_link_click',
  Sv_Health_Tos_Privacy_Policy_Click = 'sv_health_tos_privacy_policy_click',

  Login_Hyperlink_Click = 'login_hyperlink_click',
  //Login page
  Login_Button_Click = 'login_button_click',
  Signup_Hyperlink_Click = 'signup_hyperlink_click',

  //User already exists modal :
  Existing_User_Modal_Proceed = 'existing_user_modal_proceed',
  Existing_User_Modal_Return_To_Signup = 'existing_user_modal_return_to_signup',
  Existing_User_Modal_Click_Outside = 'existing_user_modal_click_outside',

  //Verify phone number modal:
  phone_verification_filled = 'phone_verification_filled',
  phone_verification_verify_button_click = 'phone_verification_verify_button_click',
  Phone_verification_back_button_click = 'Phone_verification_back_button_click',
  Phone_Verification_Success = 'phone_verification_success',
  Phone_Verification_Failure = 'phone_verification_failure',

  //
  User_Login = 'user_login',
  User_Signup = 'user_signup',
  user_magic_link_signup = 'user_magic_link_signup',
  magic_link_to_web_app = 'magic_link_to_web_app',

  //share page
  Share_Video_Page = 'share_video_page',

  //Create video screen:
  Create_Video_Page = 'create_video_page',
  Create_Video_Page_file_selected = 'create_video_page_file_selected',
  Upload_Button_Click = 'upload_button_click',
  Capture_Button_Click = 'capture_button_click',

  //Record video page:
  Record_Video_Page = 'record_video_page',
  Back_Button_Click = 'back_button_click',
  Record_Button_Click = 'record_button_click',
  Record_Page_Upload_Button_Click = 'record_page_upload_button_click',
  Record_Video_Page_File_Selected = 'record_video_page_file_selected',

  //VIDEO Created:
  Upload_Initiated = 'upload_initiated',
  Upload_Completed = 'upload_completed',
  Upload_Failed = 'upload_interrupted',
  Video_Created = 'video_created',
  Creating_resumable_failure = 'create_resumable_url_failure',
  //

  // new Upload Events
  resume_button ='resume_button_click',

  //My videos page:
  My_Videos_Page = 'my_videos_page',
  My_Videos_Page_Share_Button_Click = 'my_videos_page_share_button_click',
  My_Videos_Sort_Click = 'my_videos_sort_click',
  My_Videos_Page_Sort_Date_Added_Click = 'my_videos_page_sort_date_added_click',
  My_Videos_Page_Sort_Points_Click = 'my_videos_page_sort_points_click',
  My_Videos_Page_Sort_Shares_Click = 'my_videos_page_sort_shares_click',
  My_Videos_Page_Sort_Direction_Click = 'my_videos_page_sort_direction_click',

  //Verification page screen:
  Phone_Verification_Page = 'phone_verification_page',

  //Points page:
  My_Reward_Points_Page = 'my_reward_points_page',

  //Top navigation bar:
  Top_Bar_Create_Button_Click = 'top_bar_create_button_click',
  Top_Bar_My_Videos_Button_Click = 'top_bar_my_videos_button_click',
  Top_Bar_Points_Button_Click = 'top_bar_points_button_click',
  Top_Bar_Menu_Button_Click = 'top_bar_menu_button_click',

  //The menu:
  Menu_Open = 'menu_open',
  Menu_Close = 'menu_close',
  Menu_My_Profile_Click = 'menu_my_profile_click',
  Menu_Create_Click = 'menu_my_create_click',
  Menu_My_Videos_Click = 'menu_my_videos_click',
  Menu_Goals_Click = 'menu_my_goals_click',
  Menu_Help_Chat_Click = 'menu_help_chat_click',
  Menu_Tos_Click = 'menu_tos_click',
  Menu_Logout_Click = 'menu_logout_click',
  Menu_Close_Button_Click = 'menu_close_button_click',

  // Video generation
  Thumbnail_Generated = 'thumbnail_generated',
  Thumbnail_Generation_Failed = 'thumbnail_generation_failed',

  Video_Overlay_Generated = 'video_overlay_generated',
  Video_Overlay_Generation_Fail = 'video_overlay_generation_fail',

  Overlay_Filter_Id_Generated = 'overlay_filter_id_generated',
  Overlay_Filter_Id_Generation_Fail = 'overlay_filter_id_generation_fail',

  Video_With_Overlay_Generated = 'video_with_overlay_generated',
  Video_With_Overlay_Generation_Fail = 'video_with_overlay_generation_fail',
  CreateVideoClick = 'create_video_click',
  RecordVideoError = 'record_video_error',
  SubmitRecordedVideo = 'submit_recorded_video',
  FileSizeLimitError = 'file_size_limit_error',

  //Welcome Video page
  SkipButtonClick = 'skip_button_click',
  InvalidTokenHandlerCall = 'invalid_token_handler_call',
  GetRequestCall = 'get_request_call',
  PostRequestCall = 'post_request_call',
  PutRequestCall = 'put_request_call',
  PatchRequestCall = 'path_request_call',
  LogoutCall = 'logout_call',
}

export type EventNameKey = keyof typeof EventNames;

export enum LayoutMode {
  Desktop = 1,
  Tablet,
  Mobile,
}

export enum VideoFlowErrorMessages {
  ctaText = 'Unfortunately, we cannot save the button without a text. \n Please add a CTA Text.',
  metaTitle = 'Unfortunately, we cannot save empty meta title. \n Please add a Meta Title.',
  buttonUrl = 'Unfortunately, we cannot save the button without a link. \n Please add a CTA Url.',
}

export const BREAK_HEIGHT = 700;

export enum CampaignObjectives {
  VideoUpload = 'VIDEO_UPLOAD',
  VideoViews = 'VIDEO_VIEW',
  VideoClicks = 'VIDEO_CTA_CLICK',
}
