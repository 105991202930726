import { FC } from 'react';
import { Box } from '@mui/system';
import { NavigationPanelStrings } from '../../localization/en';
import { useTypedSelector } from '../../../store/store';
import { useStyles } from './NavigationPanel.styles';
import { UploadProcessStep } from '../../../store/storeModels';

interface Props {
  activeIndex: number;
  isSharePage?: boolean;
}

export const NavigationPanel: FC<Props> = ({ activeIndex, isSharePage }) => {
  const classes = useStyles();
  const { currentCampaign } = useTypedSelector((state) => state.rewards);
  const { currentStep } = useTypedSelector((state) => state.upload);

  const uploadStep =
    currentStep === UploadProcessStep.Uploaded || isSharePage
      ? NavigationPanelStrings.Uploaded
      : currentStep === UploadProcessStep.VideoUploading
      ? NavigationPanelStrings.Uploading
      : NavigationPanelStrings.Upload;

  const tabItems = [NavigationPanelStrings.Welcome, uploadStep, NavigationPanelStrings.Complete];

  const renderItems = tabItems.filter(
    (_, index) => (currentCampaign?.welcomeVideoUrl && currentCampaign?.showWelcomeVideo) || index > 0,
  );

  return (
    <Box className={classes.container}>
      {renderItems.map((tabName, index) => {
        const isActiveIndex = index === activeIndex;

        return (
          <Box
            className={classes.tabItem}
            key={tabName}
            sx={{
              borderTop: isActiveIndex ? 'unset' : '1px solid #2D11AD',
              borderBottom: isActiveIndex ? '1px solid #2D11AD' : 'unset',
              borderRight: isActiveIndex && index < renderItems.length - 1 ? '1px solid #2D11AD' : 'unset',
              borderLeft: isActiveIndex && index > 0 ? '1px solid #2D11AD' : 'unset',
              color: isActiveIndex || tabName === NavigationPanelStrings.Uploading ? '#2D11AD' : '#999',
              background: isActiveIndex
                ? 'transparent'
                : index < activeIndex
                ? 'rgba(0, 0, 0, 0.10)'
                : 'rgba(255, 255, 255, 0.60)',
            }}
          >
            {tabName}
          </Box>
        );
      })}
    </Box>
  );
};
