import { FC, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { openDB } from 'idb';
import { Layout } from '../../common/components/Layout/Layout';
import { AuthHandler } from '../../common/components/AuthHandler/AuthHandler';
import { AppRoutes } from '../../common/constants/routes';
import { PrivacyPolicyPage } from '../PrivacyPolicyPage/PrivacyPolicyPage';
import { SignInPage } from '../SignInPage/SignInPage';
import { LoginWithMagicLink } from '../LoginWithMagicLink/LoginWithMagicLink';
import ProfilePage from '../../../src/pages/ProfilePage/ProfilePage';
import SharePage from '../SharePage/SharePage';
import Socialversepage from '../../../src/pages/SocialversesPage/SocialversesPage';
import { SignUpFlowPage } from '../SignUpFlowPage/SignUpFlowPage';
import { httpClient } from '../../services/httpClient/httpClient';
import { usePageVisibility } from 'react-page-visibility';
import { useAppDispatch, useTypedSelector } from '../../store/store';
import { getUserRequest } from '../../store/slices/me';
import { getCampaignById, getPrimaryCampaign, setCurrentCampaign } from '../../store/slices/rewards';
import { CampaignApiModel } from '../../api/models/rewards';
import { useRouter } from 'next/router';

const CrispWithNoSSR = dynamic(() => import('../../../src/services/crisp/CrispChat'), { ssr: false });
// const ProfilePage = dynamic(() => import('../../../src/pages/ProfilePage/ProfilePage'));
const NotExistPage = dynamic(() => import('../../../src/pages/NotExistPage/NotExistPage'));
// const SharePage = dynamic(() => import('../SharePage/SharePage'));

const MagicLinkPage = dynamic(() => import('../../../src/pages/MagicLinkPage/MagicLinkPage'));

const DEFAULT_WAIT_TIME = 2000;

export const HomePage: FC = () => {
  const dispatch = useAppDispatch();
  const {
    account: { id: accountId },
  } = useTypedSelector((state) => state.account);
  const { currentCampaign } = useTypedSelector((state) => state.rewards);
  const queryShortCode = useRouter().query['cid'] as string;
  const isVisible = usePageVisibility();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.addEventListener('online', () => {
      // Notify the service worker to retry uploads
      navigator.serviceWorker.controller?.postMessage({ type: 'RETRY_UPLOADS' });
    });
  }, []);

  useEffect(() => {
    const createStore = async () => {
      await openDB('VideoUpload', 1, {
        upgrade(db) {
          console.log('!db.objectStoreNames', db.objectStoreNames);
          if (!db.objectStoreNames.contains('uploads')) {
            const maybe = db.createObjectStore('uploads', { keyPath: 'id' });
            console.log('maybe', maybe);
          } else {
            console.log('db storenames', db.objectStoreNames);
          }
        },
      });
    };
    createStore();
  }, []);
  // useEffect(() => {
  //   let registration: any;
  //   if ('serviceWorker' in navigator) {
  //     window.addEventListener('online', () => {
  //       console.log('Network is online. Retrying uploads.');
  //       navigator.serviceWorker.ready.then((registration) => {
  //         if ('sync' in registration) {
  //           registration.sync.register('retry-video-upload').catch((err) => {
  //             console.error('Fallback sync registration failed:', err);
  //           });
  //         }
  //       });
  //     });
  //     navigator.serviceWorker
  //       .register('/service-worker.js')
  //       .then((res) => {
  //         registration = res;
  //         console.log('service worker successfully registrated');
  //       })
  //       .catch((err) => {
  //         console.log('could not register service worker', err);
  //       });
  //   }
  //   return () => {
  //     if (!!registration) {
  //       registration?.unregister();
  //     }
  //   };
  // }, []);

  useEffect(() => {
    // needed for auto logout on tab activation when token is expired
    const userId = httpClient.getUserId();
    if (isVisible && userId) {
      dispatch(getUserRequest(userId));
    }
  }, [dispatch, isVisible]);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    const startTime = new Date().getTime();
    setIsLoading(true);

    const currentCampaignShortCode = queryShortCode || localStorage.getItem('campaignId');
    if (currentCampaignShortCode) {
      dispatch(
        getCampaignById({
          id: currentCampaignShortCode,
        }),
      ).then((data) => {
        const campaign = data.payload as CampaignApiModel;
        dispatch(setCurrentCampaign(campaign));
        if (campaign.id) {
          localStorage.setItem('campaignId', campaign.id);
        } else {
          localStorage.removeItem('campaignId');
        }

        const elapsed = new Date().getTime() - startTime;
        timer = setTimeout(() => setIsLoading(false), Math.max(DEFAULT_WAIT_TIME - elapsed, 0));
      });
    } else if (accountId) {
      dispatch(
        getPrimaryCampaign({
          accountId,
        }),
      ).then((data) => {
        const campaign = data.payload as CampaignApiModel;
        dispatch(setCurrentCampaign(campaign));

        const elapsed = new Date().getTime() - startTime;
        timer = setTimeout(() => setIsLoading(false), Math.max(DEFAULT_WAIT_TIME - elapsed, 0));
      });
    }

    return () => clearTimeout(timer);
  }, [queryShortCode, dispatch, accountId]);

  useEffect(() => {
    if (currentCampaign && currentCampaign?.welcomeVideoUrl) {
      fetch(`${currentCampaign?.welcomeVideoUrl?.replace(/\....$/, '.jpg').replace('/upload/', '/upload/so_0,')}`);
    }
  }, [currentCampaign]);

  const isWindow = typeof window !== 'undefined';
  if (!isWindow) {
    return <div></div>;
  }

  return (
    <Router>
      <Layout>
        {process.env.NODE_ENV !== 'development' && <CrispWithNoSSR />}
        <AuthHandler>
          <Switch>
            <Route exact path={AppRoutes.Home}>
              <SignUpFlowPage isDataLoading={isLoading} />
            </Route>
            <Route exact path={`${AppRoutes.Auth}/:token`}>
              <LoginWithMagicLink />
            </Route>
            <Route exact path={`${AppRoutes.socialverse}/:id`}>
              <Socialversepage />
            </Route>
            <Route exact path={`${AppRoutes.Auth}`}>
              <MagicLinkPage />
            </Route>
            <Route exact path={AppRoutes.NotExist}>
              <NotExistPage />
            </Route>
            <Route exact path={AppRoutes.SignIn}>
              <SignInPage />
            </Route>

            <Route exact path={AppRoutes.PrivacyPolicy}>
              <PrivacyPolicyPage />
            </Route>
            <Route path={`${AppRoutes.Share}/:id`}>
              <SharePage />
            </Route>
            <Route path={`${AppRoutes.Profile}/:tabName`}>
              <ProfilePage />
            </Route>
            <Route path="*">
              <Redirect to={AppRoutes.Home}></Redirect>
            </Route>
          </Switch>
        </AuthHandler>
      </Layout>
    </Router>
  );
};
